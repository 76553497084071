import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import moment from "moment";
import { Message } from "primereact/message";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
export const AdminInrWithdraw = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [deposits, setDeposits] = useState({});
  const [visible, setVisible] = useState(false);
  const [rejectId, setRejectId] = useState("")
  const [reason, setReason] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getinrwithdraw", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setDeposits(resp.data.inrdeposit);
      }
    };
    fetchData();
  }, []);
  const acceptWithdraw = async (tranid) => {
    const resp = await axios.post(
      url + "/admin/acceptinrwithdraw",
      {
        tranid,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Approved Successfully.",
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
  };
  const confirmReject =async () =>{
    const resp = await axios.post(
      url + "/admin/rejectinrwithdraw",
      {
        rejectId,
        reason
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if(resp.status===200){
      setVisible(false)
      setRejectId("")
      setReason("")
    }
  }
  const footerContent = (
      <div>
          <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
          <Button label="Confirm" icon="pi pi-check" onClick={() => confirmReject()} autoFocus />
      </div>
  );
  const tranAccept = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-check-square"
          severity="info"
          size="small"
          onClick={() => acceptWithdraw(rowData._id)}
        />
      </>
    );
  };
  const rejectWithdraw = (rowId) => {
    setRejectId(rowId);
    setVisible(true)
  };
  const tranReject = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-trash"
          severity="warning"
          size="small"
          onClick={() => rejectWithdraw(rowData._id)}
        />
      </>
    );
  };
  const trandate = (rowData) => {
    return <>{moment(rowData.tran_date).format("YYYY-MM-DD")}</>;
  };
  const amount = (rowData) => {
    return <>{(rowData.amount * 1).toFixed(2)}</>;
  };
  const tds = (rowData) => {
    return <>{(((rowData.amount * 1).toFixed(2) * 1) / 100).toFixed(2)}</>;
  };
  const withfee = (rowData) => {
    return <>15.00</>;
  };
  const finalamount = (rowData) => {
    return (
      <div className="text-primary text-2xl text-bold">
        {parseInt(
          rowData.amount - 15 -
            (((rowData.amount * 1).toFixed(2) * 1) / 100).toFixed(2)
        )}
      </div>
    );
  };
  return (
    <div style={{ padding: "80px 20px" }}>
      <Toast ref={toast} />
      <Message
        text="INRWITHDRAW"
        style={{ width: "100%", marginBottom: 5, justifyContent: "left" }}
      />
      <DataTable value={deposits} size="small" style={{ width: "100%" }}>
        <Column field={trandate} header="Date"></Column>
        <Column field="user_name" header="User Name"></Column>
        <Column field="mobile" header="Mobile"></Column>
        <Column field="accno" header="Acc. No"></Column>
        <Column field="bank" header="Bank"></Column>
        <Column field="branch" header="Branch"></Column>
        <Column field="ifsc" header="IFSC"></Column>
        <Column field={amount} header="Base Amount"></Column>
        <Column field={tds} header="Tds Amount"></Column>
        <Column field={withfee} header="Withdraw Chr."></Column>
        <Column field={finalamount} header="Final Amount"></Column>
        <Column header="Action" body={tranAccept}></Column>
        <Column header="Action" body={tranReject}></Column>
      </DataTable>
      <Dialog header="Reject Reason" visible={visible} style={{ width: '50vw' }} onHide={() => {if (!visible) return; setVisible(false); }} footer={footerContent}>
                <div>
                  <InputText placeholder="Reason" value={reason} onChange={e => setReason(e.target.value)} style={{width:'100%'}} />
                </div>
            </Dialog>
    </div>
  );
};
