import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.scss";
import axios from "axios";
import { Loader } from "../../../components/usercomponent/Loader";
import { Message } from "primereact/message";
import { Divider } from "primereact/divider";
import Button from "@mui/material/Button";
import Cookie from "js-cookie";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Toast } from "primereact/toast";
import { TickerWidget } from "../../../tradingview/TickerWidget";
import { Dialog } from "primereact/dialog";
export const DashBoard = () => {
  const toast = useRef(null);
  const Navigate = useNavigate();
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [visible, setVisible] = useState(false);
  const uuid = Cookie.get("srxuuid");
  const token = Cookie.get("srxtoken");
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .post(
          url + "/user/getdashboarddata",
          {
            uuid,
          },
          {
            headers: {
              "x-api-key": apikey,
              "act-sessionid": token,
            },
          }
        )
        .then(function (resp) {
          if (resp.status === 200) {
            setData(resp.data.userdata);
            setVisible(!resp.data.userdata.mfa_enabled);
            setLoader(false);
          }
        })
        .catch(function (error) {
          Navigate("/");
        });
    };
    fetchData();
  }, []);
  const inrdeposit = (
    <div
      className="flex flex-column align-items-center gap-1"
      style={{ width: "100%" }}
    >
      <div className="title">FIAT</div>
      <div className="title">CURRENCY</div>
      {data.kyc_level === 5 ? (
        <div className="flex gap-2">
          <Button
            variant="contained"
            color="success"
            startIcon={<CloudDownloadIcon />}
            href="/user/inrwallet"
          >
            Navigate
          </Button>
        </div>
      ) : (
        <Button variant="outlined" color="error">
          Paused
        </Button>
      )}
    </div>
  );
  const cryptodeposit = (
    <div
      className="flex flex-column align-items-center gap-1"
      style={{ width: "100%" }}
    >
      <div className="title">CRYPTO</div>
      <div className="title">CURRENCY</div>
      {data.kyc_level === 5 ? (
        <div className="flex gap-2">
          <Button
            variant="contained"
            color="success"
            startIcon={<CloudDownloadIcon />}
            href="/user/cryptodeposit"
          >
            Navigate
          </Button>
        </div>
      ) : (
        <Button variant="outlined" color="error">
          Paused
        </Button>
      )}
    </div>
  );
  const quickTrade = (
    <div
      className="flex flex-column align-items-center gap-1"
      style={{ width: "100%" }}
    >
      <div className="title">QUICK TRADE</div>
      <div className="title">Easy Access</div>
      {data.kyc_level === 5 ? (
        <div className="flex gap-2">
          <Button
            variant="contained"
            color="success"
            startIcon={<BarChartIcon />}
            href="/user/spottrading/soarxinr"
          >
            Navigate
          </Button>
        </div>
      ) : (
        <Button variant="outlined" color="error">
          Paused
        </Button>
      )}
    </div>
  );
  const reflink = (
    <div className="flex gap-2 align-items-center">
      <div>Ref. Link: </div>
      <div className="refmessage">
        https://soarxbits.com/signup/{data.platform_id}
      </div>
      <i
        className="pi pi-copy cursor-pointer"
        onClick={() =>
          copyText(`https://soarxbits.com/signup/${data.platform_id}`)
        }
      />
    </div>
  );
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Content copied successfully.",
    });
  };
  return (
    <div className="dashboard surface-ground">
      <Toast ref={toast} />
      {loader === true ? (
        <Loader />
      ) : (
        <>
          <div className="hero">
            <div className="grid">
              <div className="col-12 md:col-12 lg:col-5 left">
                <div className="headdata">
                  <div className="wel">WELCOME</div>
                  <div className="user">
                    {data.first_name === null || data.first_name === "" ? (
                      "GUEST USER"
                    ) : (
                      <>
                        <div className="card">
                          <Message
                            style={{
                              border: "solid #696cff",
                              borderWidth: "0 0 0 6px",
                            }}
                            className="border-primary w-full justify-content-start"
                            severity="info"
                            content={
                              "💐 Welcome back " + data.first_name.toUpperCase()
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <Divider />
                  <div className="title">SOARXBIT'S</div>
                  <Divider />
                  <div className="headone">Let's Witness</div>
                  <div className="headtwo">Smooth Trading</div>
                  <div className="headthree">Experience</div>
                </div>
                <Divider />
                <div className="status">
                  {data.kyc_level === 1 ? (
                    <Button
                      variant="contained"
                      color="error"
                      href="user/mobileverification"
                    >
                      Please Verify Mobile
                    </Button>
                  ) : (
                    <>
                      {data.kyc_level === 2 ? (
                        <Button
                          variant="contained"
                          color="error"
                          href="/user/uploadkyc"
                        >
                          Provide KYC Details
                        </Button>
                      ) : (
                        <>
                          {data.kyc_level === 3 ? (
                            <Button
                              variant="contained"
                              color="error"
                              href="/user/uploadkycdoc"
                            >
                              Please Upload KYC
                            </Button>
                          ) : (
                            <>
                              {data.kyc_level === 4 ? (
                                <Message
                                  severity="info"
                                  text="KYC Under verification. can take upto 24hrs."
                                />
                              ) : (
                                <Message
                                  severity="success"
                                  text="Enjoy Smooth Trade!!!"
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <Divider />
              </div>
              <div className="col-12 md:col-12 lg:col-7 right flex flex-column gap-3">
                <div className="msg">
                  {data.kyc_level === 5 ? (
                    <Message
                      text="Quick Deposit & Withdraw"
                      style={{ width: "100%", justifyContent: "left" }}
                    />
                  ) : (
                    <Message
                      severity="error"
                      text="Please Complete KYC to Proceed"
                      style={{ width: "100%", justifyContent: "left" }}
                    />
                  )}
                </div>
                <div className="leftcont">
                  <div className="grid">
                    <div className="col-6">
                      {data.kyc_level === 5 ? (
                        <Message
                          style={{ width: "100%" }}
                          content={inrdeposit}
                        />
                      ) : (
                        <Message
                          severity="error"
                          style={{ width: "100%" }}
                          content={inrdeposit}
                        />
                      )}
                    </div>
                    <div className="col-6">
                      {data.kyc_level === 5 ? (
                        <Message
                          style={{ width: "100%" }}
                          content={cryptodeposit}
                        />
                      ) : (
                        <Message
                          severity="error"
                          style={{ width: "100%" }}
                          content={cryptodeposit}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Message
                  content={reflink}
                  style={{ justifyContent: "left" }}
                  className="reflink"
                />
                <div className="leftcont">
                  <div className="grid">
                    <div className="col-12">
                      {data.kyc_level === 5 ? (
                        <Message
                          style={{ width: "100%" }}
                          content={quickTrade}
                        />
                      ) : (
                        <Message
                          severity="error"
                          style={{ width: "100%" }}
                          content={quickTrade}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TickerWidget />
          <div className="whywe p-5">
            <div className="grid">
              <div className="col-12 md:col-6 lg:col-6 flex flex-column gap-3">
                <div className="text-primary font-bold text-5xl">WHY WE?</div>
                <div className="text-300 font-bold text-2xl">INTRODUCTION</div>
                <div>
                  SOARXBITS is a cryptocurrency trading platform designed
                  specially to assure smooth experience of trading.
                </div>
                <div>
                  It is a reliable and secure way to buy, sell and trade crypto
                  with low fee and unmatched liquidity. You can try yourself
                  with SOARXBITS demo trading account, apply for free crypto,
                  and play games to earn even more crypto.
                </div>
                <div>
                  Best of all, we give you access to the widest variety of
                  cryptocurrencies on the market.
                </div>
                <div className="text-primary font-bold text-5xl">
                  Key Features
                </div>
                <Message
                  severity="success"
                  text="Interactive User Interface."
                  style={{ width: "100%", justifyContent: "left" }}
                />
                <Message
                  severity="success"
                  text="Hassall free execution."
                  style={{ width: "100%", justifyContent: "left" }}
                />
                <Message
                  severity="success"
                  text="Quick Buy and Sell Mechanism."
                  style={{ width: "100%", justifyContent: "left" }}
                />
                <Message
                  severity="success"
                  text="Interactive User Interface."
                  style={{ width: "100%", justifyContent: "left" }}
                />
                <Message
                  severity="success"
                  text="24 X 7 Live support system."
                  style={{ width: "100%", justifyContent: "left" }}
                />
              </div>
            </div>
          </div>
          <div>
            {data.mfa_enabled === false ? (
              <Dialog
                header="2FA Activation"
                visible={visible}
                style={{ width: "100vw" }}
                onHide={() => {
                  if (!visible) return;
                  setVisible(false);
                }}
              >
                <div className="flex flex-column gap-2">
                  <Message
                    text="You Have not Activated 2FA"
                    severity="error"
                    style={{ justifyContent: "left", width: "100%" }}
                  />
                  <Message
                    text="Please activate for Secure Login."
                    severity="success"
                    style={{ justifyContent: "left", width: "100%" }}
                  />
                  <Button variant="contained" href="/user/security">
                    Setup Now
                  </Button>
                </div>
              </Dialog>
            ) : (
              "Not Hi"
            )}
          </div>
        </>
      )}
    </div>
  );
};
