import React, { useEffect, useRef, useState } from "react";
import "./spotwallet.scss";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Message } from "primereact/message";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Cookie from "js-cookie";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { QRCodeCanvas } from "qrcode.react";
export const SpotWallet = () => {
  const toast = useRef(null);
  let { currency } = useParams("currency");
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const [balance, setBalance] = useState({
    balance:0,
    frozen:0,
    inuse:0
  });
  const [address, setAddress] = useState("");
  const [btnwithdraw, setBtnWithdraw] = useState(0);
  const [btnotp, setBtnOtp] = useState(0);
  const [withadd, setwithadd] = useState(0);
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [withamt, setWithAmt] = useState(0);
  const [bepadd, setBepAdd] = useState("");
  const [otp, setOtp] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const details = await axios.post(
        url + "/user/getcryptwithdetails",
        {
          uuid,
          currency
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if(details.status===200){
        setAddress(details.data.depositadd)
        setwithadd(details.data.withaddress)
        setBalance(details.data.balance)
      }
    };
    fetchData();
  }, []);
  const activecoindetails = (
    <div className="activecoindetails">
      <div className="total">Total: {balance.balance}</div>
      <div className="available">Lock: {balance.frozen}</div>
      <div className="lock">Available:{balance.inuse} </div>
    </div>
  );
  const submitHandle = async () => {
    const resp = await axios.post(
      url + "/user/withaddauthconf",
      {
        uuid,
        bepadd,
        otp,
        otp_for: "WITHDRAWADD",
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      const respwithadd = await axios.post(
        url + "/user/getwithaddress",
        {
          uuid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (respwithadd.status === 200) {
        setwithadd(respwithadd.data.withdrawadd);
        setVisible(false);
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
    }
  };
  const sendOtp = async () => {
    setBtnOtp(1);
    const resp = await axios.post(
      url + "/user/withaddauthinit",
      {
        uuid,
        otp_for: "WITHDRAWADD",
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: resp.data.success,
      });
      // setBtnOtp(2)
      // setBtnSubmit(1)
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: resp.data.error,
      });
      setBtnOtp(0);
    }
  };
  const setWithdrawAddress = (
    <div className="flex align-items-center gap-2">
      <div>Withdraw Address Not Set!!!</div>
      <Button label="Set It" size="small" onClick={() => setVisible(true)} />
    </div>
  );
  const getQrImage = () => {
    // setQrImg(qrcode.toDataURL("abc"));
    setShow(true);
  };
  const copyText = (x) => {
    navigator.clipboard.writeText(x);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Content copied successfully.",
    });
  };
  const depositaddress = (
    <div className="flex gap-2">
      <div>{address}</div>
      <i
        className="pi pi-qrcode"
        style={{ cursor: "pointer" }}
        onClick={() => getQrImage()}
      />
      <i
        className="pi pi-copy"
        style={{ cursor: "pointer" }}
        onClick={() => copyText(address)}
      />
    </div>
  );
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        onClick={() => submitHandle()}
        autoFocus
      />
    </div>
  );
  const setPer = (val) => {
    setWithAmt((balance.inuse * val) / 100);
  };
  const handleWithdraw = () =>{
    if((withamt*1 > balance.inuse*1)||(withamt*1<1000)){
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Value not matched.",
      });
      return false
    }
    setBtnWithdraw(1)
    axios.post(url + `/user/cryptowithdraw/${currency}`,{uuid, withamt},{
      headers: {
        "x-api-key": apikey,
      },
    }).then(function(resp){
      if(resp.status===200){
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Withdraw successfully done...",
        });
        setBtnWithdraw(2)
      }else{
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Something went wrong.",
        });
        setBtnWithdraw(0)
      }
    }).catch(function(error){
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong.",
      });
      setBtnWithdraw(0)
    })
  }
  return (
    <div className="spotwallet">
      <Toast ref={toast} />
      <div className="content">
        {/* <Message content={activecoin}>Hi</Message> */}
        <Message content={activecoindetails} />
        <div className="activecoinaction">
          <TabView>
            <TabPanel header="Deposit" className="flex flex-column gap-2">
              <div className="border-2 border-dashed surface-border border-round surface-ground p-2">
                Network
              </div>
              <Message
                severity="info"
                text="Any EVM Compatible Smart Chain"
                style={{ justifyContent: "left" }}
              />
              <Message
                style={{ justifyContent: "left" }}
                severity="warn"
                text="Make sure that the network matches the one you choose to avoid losses when withdrawing funds"
              />
              <div className="border-2 border-dashed surface-border border-round surface-ground p-2">
                Deposit Address
              </div>
              <div className="depaddress flex align-items-center justify-content-between">
                <div
                  className="add flex align-items-center justify-content-between gap-1"
                  style={{ width: "100%" }}
                >
                  <Message
                    style={{
                      fontSize: "12px",
                      width: "100%",
                      justifyContent: "left",
                    }}
                    content={depositaddress}
                  />
                  {/* {address === "" ? (
                    <>
                      <Message
                        severity="error"
                        content="Address Not Defined! Click + Button"
                      />
                      <Message  />
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        gap: "3px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Message style={{ fontSize: "12px" }} content={address} />
                      <Message content={qrdepostaddress} />
                      <Message content={copydepostaddress} />
                    </div>
                  )} */}
                </div>
              </div>
              <div className="flex flex-column justify-content-between border-2 border-dashed surface-border border-round surface-ground p-2 gap-3">
                <Message
                  style={{ justifyContent: "left" }}
                  severity="warn"
                  text="Make sure that the network matches the one you choose to avoid losses when withdrawing funds"
                />
              </div>
            </TabPanel>
            <TabPanel header="Withdraw" className="flex flex-column gap-2">
              <div className="border-2 border-dashed surface-border border-round surface-ground p-2">
                Network
              </div>
              <Message
                severity="info"
                text="EVM Smart Chain"
                style={{ justifyContent: "left" }}
              />
              <Message
                severity="warn"
                text="Make sure that the network matches the one you choose to avoid losses when withdrawing funds"
                style={{ justifyContent: "left" }}
              />
              <div className="border-2 border-dashed surface-border border-round surface-ground p-2">
                Withdraw Address
              </div>
              <div className="depaddress flex align-items-center justify-content-between">
                <div
                  className="add flex align-items-center justify-content-between gap-1"
                  style={{ width: "100%" }}
                >
                  {withadd === "" ? (
                    <Message
                      severity="warn"
                      content={setWithdrawAddress}
                      style={{ width: "100%", justifyContent: "left" }}
                    />
                  ) : (
                    <Message
                      severity="warn"
                      content={withadd}
                      style={{ width: "100%", justifyContent: "left" }}
                    />
                  )}
                </div>
              </div>
              <div className="flex border-2 border-dashed surface-border border-round surface-ground justify-content-between p-2">
                <div>Withdraw Amount</div>
                <div>{balance.inuse}</div>
              </div>
              <div className="p-inputgroup flex-1">
                <InputText
                  keyfilter={"pint"}
                  placeholder="Amount"
                  size={"small"}
                  value={withamt}
                  onChange={(e) => setWithAmt(e.target.value)}
                  className="p-inputtext-sm"
                />
                <Button
                  label="Max"
                  outlined
                  className="p-button-primary"
                  size={"small"}
                  onClick={() => setPer(100)}
                />
              </div>
              <Message text="Withdraw fee will deduct from amount." style={{justifyContent:"left"}} />
              <div className="flex border-2 border-dashed surface-border border-round surface-ground align-items-center justify-content-between p-1">
                <Button
                  severity="danger"
                  onClick={() => setPer(25)}
                  size="small"
                  label="25%"
                  style={{ width: "20%" }}
                />
                <Button
                  severity="danger"
                  onClick={() => setPer(50)}
                  size="small"
                  label="50%"
                  style={{ width: "20%" }}
                />
                <Button
                  severity="danger"
                  onClick={() => setPer(75)}
                  size="small"
                  label="75%"
                  style={{ width: "20%" }}
                />
                <Button
                  severity="danger"
                  onClick={() => setPer(100)}
                  size="small"
                  label="100%"
                  style={{ width: "20%" }}
                />
              </div>
              <div className="p-inputgroup flex-1">
                {withadd !== "" ? (
                  <>
                  {btnwithdraw===0?
                  <Button
                  label="Withdraw"
                  severity="danger"
                  size={"small"}
                  style={{ width: "100%" }}
                  onClick={()=>handleWithdraw()}
                />
                  :
                  <>
                  {btnwithdraw===1?
                  <Button
                  label="Proccessing......"
                  severity="danger"
                  size={"small"}
                  style={{ width: "100%" }}
                />
                  :
                  <Button
                  label="Submited"
                  severity="danger"
                  disabled
                  size={"small"}
                  style={{ width: "100%" }}
                />
                  }
                  </>
                  }
                  </>
                  
                ) : (
                  <Button
                    label="Please Confirm Address"
                    severity="danger"
                    disabled
                    size={"small"}
                    style={{ width: "100%" }}
                  />
                )}
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
      <Dialog
        header="Set Withdraw Address"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
        footer={footerContent}
      >
        <div className="flex flex-column gap-2">
          <Button
            label="OTP"
            onClick={() => sendOtp()}
            disabled={btnotp === 0 ? false : true}
          />
          <div>
            <InputText
              keyfilter={"pint"}
              placeholder="Mobile OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              disabled={btnotp === 0 ? true : false}
            />
          </div>
          <div>
            <InputText
              placeholder="Withdraw Address [SRX-20]"
              style={{ width: "100%" }}
              value={bepadd}
              onChange={(e) => setBepAdd(e.target.value)}
              disabled={btnotp === 0 ? true : false}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Deposit Link"
        visible={show}
        onHide={() => {
          if (!show) return;
          setShow(false);
        }}
      >
        <div className="p-1" style={{ backgroundColor: "#FFFF" }}>
          <QRCodeCanvas value={address} size={200} />
        </div>
      </Dialog>
    </div>
  );
};
