import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
export const MasterTradeBuy = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const [bid, setBid] = useState({})
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(
        url + "/user/getorders/soarxinr/Bid",
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setBid(resp.data.buys);
      }
    };
    fetchData();
  }, []);
  const price = (data) => (
    <div>{(data[0]/10e7).toFixed(3)}</div>
  )
  const amount = (data) => (
    <div>{data[1]/10e7}</div>
  )
  const total = (data) => (
    <div>{parseFloat((data[1]/10e7)/(data[0]/10e7)).toFixed(4)}</div>
  )
  return (
    <div>
      <DataTable value={bid} size="small" style={{ width: "100%", fontSize:'12px', color:'red' }}>
      <Column body={price} header="Price" style={{color:'red'}}></Column>
      <Column body={amount} header="Amount"  style={{color:'red'}}></Column>
      <Column body={total} header="Qnty"  style={{color:'red'}}></Column>
    </DataTable>
    </div>
  )
}
