import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import moment from "moment";
import {
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import "./pendingkyc.scss";
import { InputText } from "primereact/inputtext";
export const PendingKyc = () => {
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const toast = useRef(null);
  const [activeId, setActiveId] = useState("");
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState();
  const [dataKyc, setDataKyc] = useState();
  const [images, setImages] = useState(null);
  const [btnPending, setBtnPending] = useState(0);
  const [visibleReject, setVisibleReject] = useState(false);
  const [rejectId, setRejectId] = useState("");
  const [reason, setReason] = useState("");
  const [data, setData] = useState({
    activedata: "",
    actvalue: "",
    actimage: "",
  });
  const [selectedReject, setSelectedReject] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(url + "/admin/getpendingkyc", {
        headers: {
          "x-api-key": apikey,
        },
      });
      if (resp.status === 200) {
        setList(resp.data.users);
      }
    };
    fetchData();
  }, []);
  const checkOpen = (pltId, tocheck) => {
    setActiveId(pltId);
    const fetchData = async () => {
      var profileData = await axios.post(
        url + "/admin/getkycdetails",
        {
          platformid: pltId,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (tocheck === "PAN") {
        setData({
          activedata: "PAN",
          actvalue: profileData.data.userdata[0].pan,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/panimage.jpg`,
        });
      } else if (tocheck === "AF") {
        setData({
          activedata: "AADHAR FRONT",
          actvalue: profileData.data.userdata[0].aadhar,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/aadharfrontimage.jpg`,
        });
      } else if (tocheck === "AB") {
        setData({
          activedata: "AB",
          actvalue: profileData.data.userdata[0].aadhar,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/aadharback.jpg`,
        });
      } else if (tocheck === "B") {
        setData({
          activedata: "BANK",
          actvalue: `${profileData.data.userdata[0].data[0].bank}/${profileData.data.userdata[0].data[0].accno}/${profileData.data.userdata[0].data[0].ifsc}`,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/cheque.jpg`,
        });
      } else if (tocheck === "S") {
        setData({
          activedata: "SELFY",
          actvalue: profileData.data.userdata[0].user_name,
          actimage: `https://soarxupload.blr1.cdn.digitaloceanspaces.com/${pltId}/selfy.jpg`,
        });
      }

      console.log(profileData.data.data);
      //setData(profileData.data.data);
    };
    fetchData();
    setOpen(true);
  };
  if (!list) return false;
  let rows = [];
  for (let i = 0; i < list.length; i++) {
    rows.push(list[i]);
  }
  // console.log(data);

  const rejects = [
    { name: "Document Level", code: "3" },
    { name: "KYC Level", code: "2" },
  ];
  const confirmApprove = async (uuid) => {
    setBtnPending(1);
    const resp = await axios.post(
      url + "/admin/approvekyc",
      { uuid },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "KYC Approved Successfully.",
      });
      setInterval(() => {
        window.location.reload(true);
      }, 1000);
      
    }
  };
  const confirmReject = async () => {
    if (!selectedReject) {
      return false;
    }
    const resp = await axios.post(
      url + "/admin/rejectKyc",
      {
        rejectId,
        reason,
        selectedReject,
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setVisibleReject(false);
      setSelectedReject(null);
      setReason("");
      window.location.reload(true);
    }
  };
  const rejFooter = (
    <div>
      <Button
        label="No"
        className="p-button-text"
        onClick={() => setVisibleReject(false)}
      />
      <Button
        label="Confirm"
        severity="contained"
        onClick={() => confirmReject()}
      />
    </div>
  );
  const footerContent = (
    <div className="p-2">
      <Button label={data.actvalue} className="p-button-text" />
      <Button
        label="PAN"
        onClick={() => checkOpen(activeId, "PAN")}
        // icon="pi pi-times"
        // onClick={() => setVisibleReject(false)}
        // className="p-button-text"
      />
      <Button
        label="AADHAR FRONT"
        onClick={() => checkOpen(activeId, "AF")}
        // icon="pi pi-check"
        // onClick={() => confirmReject()}
      />
      <Button
        label="AADHAR BACK"
        onClick={() => checkOpen(activeId, "AB")}
        // icon="pi pi-check"
        // onClick={() => confirmReject()}
      />
      <Button
        label="BANK"
        onClick={() => checkOpen(activeId, "B")}
        // icon="pi pi-check"
        // onClick={() => confirmReject()}
      />
      <Button
        label="SELFY"
        onClick={() => checkOpen(activeId, "S")}
        // icon="pi pi-check"
        // onClick={() => confirmReject()}
      />
    </div>
  );
  const rejectKyc = (platformId) => {
    setRejectId(platformId);
    setVisibleReject(true);
  };
  return (
    <div className="pendingkyc">
      <Toast ref={toast} />
      <div className="content">
        <Card>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center" className="text-primary">SL. No.</TableCell>
                <TableCell align="center" className="text-primary">Date</TableCell>
                <TableCell align="center" className="text-primary">Platform Id</TableCell>
                <TableCell align="center" className="text-primary">Full Name</TableCell>
                <TableCell align="center" className="text-primary">Check</TableCell>
                <TableCell align="center" className="text-primary">Approve</TableCell>
                <TableCell align="center" className="text-primary">Reject</TableCell>
                <TableCell align="center" className="text-primary">Paused</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow
                  key={row.uuid}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" className="text-primary">{index + 1}</TableCell>
                  <TableCell align="center" className="text-primary">
                    {moment(new Date(row.creation_date)).format("YYYY-MM-DD")}
                  </TableCell>
                  <TableCell align="center" className="text-primary">{row.platform_id}</TableCell>
                  <TableCell align="center" className="text-primary">{row.first_name}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="warning"
                      size="small"
                      onClick={() => checkOpen(row.platform_id, "PAN")}
                    >
                      Check
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => confirmApprove(row.uuid)}
                      label="Approve"
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      icon="pi pi-trash"
                      severity="warning"
                      size="small"
                      onClick={() => rejectKyc(row.platform_id)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      // onClick={() => kycPaused(row.email)}
                    >
                      Paused
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Card>
        <Dialog
          header={`KYC DOCS`}
          visible={open}
          onHide={() => {
            if (!open) return;
            setOpen(false);
          }}
          footer={footerContent}
        >
          <div className="card">
            <img src={data.actimage} alt="NO" width={'400px'} />
          </div>
        </Dialog>
      </div>
      <Dialog
        header="Reject Reason"
        visible={visibleReject}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!visibleReject) return;
          setVisibleReject(false);
        }}
        footer={rejFooter}
      >
        <div>
          <InputText
            placeholder="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            style={{ width: "100%" }}
          />
          <div className="card flex flex-1 justify-content-left">
            <Dropdown
              value={selectedReject}
              onChange={(e) => setSelectedReject(e.value)}
              options={rejects}
              optionLabel="name"
              placeholder="Select Reject Level"
              className="w-full"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};
