import React, { useRef, useState } from "react";
import "./contracttopup.scss";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ethers } from "ethers";
import { Toast } from "primereact/toast";
import abi from "../../../ABI.json";
export const ContractTopup = () => {
  const toast = useRef(null);
  const contract = "0xF930503f08CA8940BB37a777A1aCc8eb81c2B4E0";
  const [coinAmount, setCoinAmount] = useState(0);
  const [btnsubmit, setBtnSubmit] = useState(0);
  const handleSubmit = async () => {
    if (window.ethereum) {
      const adrs = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const wa = adrs[0];
      setBtnSubmit(1);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      var soar = coinAmount;
      soar = soar.toString();
      var mycontract = new ethers.Contract(contract, abi, signer);
      try {
        mycontract
          .contribute({ value: ethers.utils.parseUnits(soar, "ether") })
          .then(function (done) {
            toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Contract Toped Up Successfully.",
              });
          })
          .catch(function (error) {
            setBtnSubmit(0);
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Something went wrong....",
              });
          });
      } catch (error) {
        setBtnSubmit(0);
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Something went wrong....",
          });
      }
    }
  };
  return (
    <div className="contracttopup">
      <Toast ref={toast} />
      <div className="content">
        <div className="grid">
          <div className="col-3 ">
            <Card title="Coin Topup In Contract">
              <div className="flex flex-column gap-2">
                <InputText
                  placeholder="Coin Amount"
                  value={coinAmount}
                  onChange={(e) => setCoinAmount(e.target.value)}
                />
                {btnsubmit === 0 ? (
                  <Button label="Submit" onClick={() => handleSubmit()} />
                ) : (
                  <>
                    {btnsubmit === 1 ? (
                      <Button label="Submitting" />
                    ) : (
                      <Button label="Submitted" />
                    )}
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
