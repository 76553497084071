import React, { useEffect, useRef, useState } from "react";
import { Message } from "primereact/message";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import axios from "axios";
import Cookie from "js-cookie";
export const InrWithdrawComp = () => {
  const toast = useRef(null);
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const uuid = Cookie.get("srxuuid");
  const token = Cookie.get("srxtoken");
  const [withdraws, setWithdraws] = useState({});
  const [userbank, setUserBank] = useState({})
  const [balance, setBalance] = useState(0)
  const [withamt, setWithAmt] = useState(0)
  const [btnwithdraw, setBtnWithdraw] = useState(0)
  useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.post(
        url + "/user/getinrtransactions",
        {
          uuid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setWithdraws(resp.data.inwithdraw);
        setUserBank(resp.data.userbank);
        setBalance(resp.data.balance)
        // setDeposits(resp.data.inrdeposit);
      }
    };
    fetchData();
  }, []);
  const maxFetch = () =>{
    setWithAmt(balance)
  }
  const setPer = (val) =>{
    setWithAmt(balance*val/100)
  }
  const handleSubmit = async () =>{
    if((withamt*1>balance*1)||(withamt*1 < 200)||(withamt*1 > 7000)){
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong.",
      });
      return false
    }
    setBtnWithdraw(1)
    const resp = await axios.post(
      url + "/user/submitinrwithdraw",
      {
        uuid,
        balance,
        withamt
      },
      {
        headers: {
          "x-api-key": apikey,
        },
      }
    );
    if (resp.status === 200) {
      setBtnWithdraw(2)
      const resp = await axios.post(
        url + "/user/getinrtransactions",
        {
          uuid,
        },
        {
          headers: {
            "x-api-key": apikey,
          },
        }
      );
      if (resp.status === 200) {
        setWithdraws(resp.data.inwithdraw);
        setBalance(resp.data.balance)
      }
    }else{
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong.",
      });
      setBtnWithdraw(0)
    }

  }
  const inrwithdraw = (
    <DataTable value={withdraws} size="small" style={{ width: "100%" }}>
      <Column field="tran_date" header="Date"></Column>
      <Column field="amount" header="Amount"></Column>
      <Column field="status" header="Status"></Column>
      <Column field="reject_cause" header="Reason"></Column>
    </DataTable>
  );
  const walletbalance = (
    <div className="flex justify-content-between" style={{width:"100%"}}>
      <div>Wallet Balance</div>
      <div>{balance.toFixed(2)}</div>
    </div>
  )
  return (
    <div className="inrwithdraw flex flex-column gap-2">
      <Toast ref={toast} />
      <div className="flex border-2 border-dashed surface-border border-round surface-ground justify-content-between p-2">
        <div>Receiver Account</div>
        <div>{userbank && userbank.accno}</div>
      </div>
      <Message
        severity="warn"
        text="Make sure your account Number"
        style={{justifyContent:"left"}}
      />
      <div className="depaddress flex align-items-center justify-content-between">
        <Message content={walletbalance} style={{width:'100%', justifyContent:"left"}} />
      </div>
      <div className="p-inputgroup flex-1">
        <InputText keyfilter={"pint"} value={withamt} onChange={e=>setWithAmt(e.target.value)} placeholder="Amount" className="p-inputtext-sm" />
        <Button
          label="Max"
          severity="danger"
          outlined
          className="p-button-primary"
          onClick={()=>maxFetch()}
          size={"small"}
        />
      </div>
      
      <div className="flex border-2 border-dashed surface-border border-round surface-ground align-items-center justify-content-between p-1">
        <Button severity="danger" onClick={()=>setPer(25)} size="small" label="25%" style={{width:'20%'}} />
        <Button severity="danger" onClick={()=>setPer(50)} size="small" label="50%" style={{width:'20%'}} />
        <Button severity="danger" onClick={()=>setPer(75)} size="small" label="75%" style={{width:'20%'}} />
        <Button severity="danger"  onClick={()=>setPer(100)}size="small" label="100%" style={{width:'20%'}} />
      </div>
      <div className="flex-1 border-2 border-dashed surface-border border-round surface-ground p-2">
        {btnwithdraw===0?
          <Button severity="danger" onClick={() => handleSubmit()} label="Withdraw" size={"small"} style={{ width: "100%" }} />
        :
          <>
          {btnwithdraw===1?
           <Button severity="danger" label="Submitting..." size={"small"} style={{ width: "100%" }} />
          :
          <Button severity="danger" label="Submitted" size={"small"} style={{ width: "100%" }} />
          }
          </>
        }
       
      </div>
      <Message
        severity="error"
        text="Fee will be charged from amount."
        style={{justifyContent:"left"}}
      />
      <Message
        severity="error"
        text="Max 3Lakhs can be withdrawn."
        style={{justifyContent:"left"}}
      />
      <Message
        severity="error"
        text="Withdraw approval is subjected to Banking Partner."
        style={{justifyContent:"left"}}
      />
      <Message content={inrwithdraw} className="w-100" />
    </div>
  );
};
