import React, { useContext, useState, useEffect } from "react";
import { PrimeReactContext } from "primereact/api";
// import "./userappbar.scss";
import { Menubar } from "primereact/menubar";
import Cookie from "js-cookie";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
export const AdminAppBar = () => {
  const uuid = Cookie.get("srxuuid");
  const url = process.env.REACT_APP_HOST_ADDR;
  const apikey = process.env.REACT_APP_APIKEY;
  const token = Cookie.get("srxtoken");
  const [data, setData] = useState({});
  const Navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(
    JSON.parse(localStorage.getItem("darkMode")) || false
  );
  const { changeTheme } = useContext(PrimeReactContext);
  const changeMyTheme = () => {
    let currentTheme =
      darkMode === false ? "lara-light-teal" : "lara-dark-teal";
    let newTheme = darkMode === false ? "lara-dark-teal" : "lara-light-teal";
    changeTheme(currentTheme, newTheme, "app-theme", function () {
      setDarkMode(!darkMode);
      localStorage.setItem("darkMode", !darkMode);
    });
  };
  useEffect(() => {
    let currentTheme = darkMode === true ? "lara-light-teal" : "lara-dark-teal";
    let newTheme = darkMode === true ? "lara-dark-teal" : "lara-light-teal";
    changeTheme(currentTheme, newTheme, "app-theme", function () {
      // setDarkMode(!darkMode);
      // localStorage.setItem("darkMode", !darkMode);
    });
  }, [darkMode]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     await axios
  //       .post(
  //         url + "/user/getdashboarddata",
  //         {
  //           uuid,
  //         },
  //         {
  //           headers: {
  //             "x-api-key": apikey,
  //             "act-sessionid": token,
  //           },
  //         }
  //       )
  //       .then(function (resp) {
  //         if (resp.status === 200) {
  //           setData(resp.data.userdata);
  //         }
  //       })
  //       .catch(function (error) {
  //         Navigate("/");
  //       });
  //   };
  //   fetchData();
  // }, []);

  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
      url: "/admin/",
    },
    {
      label: "User KYC",
      icon: "pi pi-star",
      items: [
        {
          label: "Pending",
          icon: "pi pi-indian-rupee",
          url: "/admin/pendingkyc",
        },
        {
          label: "Approved",
          icon: "pi pi-bitcoin",
        },
        {
          label: "Mobile OTP",
          icon: "pi pi-bitcoin",
        },
        {
          label: "Email OTP",
          icon: "pi pi-bitcoin",
        },
      ],
    },
    {
      label: "Transactions",
      icon: "pi pi-star",
      items: [
        {
          label: "INR Deposit",
          icon: "pi pi-bolt",
          url: "/admin/inrdeposit",
        },
        {
          label: "INR Withdraw",
          icon: "pi pi-server",
          url: "/admin/inrwithdraw",
        },
        {
          label: "CRYPTO Deposit",
          icon: "pi pi-server",
        },
        {
          label: "CRYPTO Withdraw",
          icon: "pi pi-server",
        },
      ],
    },
    {
      label: "USERS",
      icon: "pi pi-star",
      items: [
        {
          label: "Wallet Balance",
          icon: "pi pi-bolt",
          url: "/admin/walletbalance",
        },
        {
          label: "Active Trade",
          icon: "pi pi-server",
        },
        {
          label: "Completed Trade",
          icon: "pi pi-server",
        },
      ],
    },
    {
      label: "Settings",
      icon: "pi pi-star",
      items: [
        {
          label: "Contract Top Up",
          icon: "pi pi-bolt",
          url: "/admin/contracttopup",
        },
        {
          label: "Security",
          icon: "pi pi-server",
          url: "/user/security",
        },
      ],
    },
  ];

  const start = (
    <div className="flex align-items-center gap-1">
      <Link to="/">
        <img alt="logo" src="/images/logo.png" height="40" className="mr-2" />
      </Link>
      <span className="headlogo">SOARXBITS</span>
    </div>
  );
  const end = (
    <div className="flex align-items-center gap-5">
      {darkMode ? (
        <span
          className="pi pi-moon"
          onClick={() => changeMyTheme()}
          style={{ cursor: "pointer" }}
        />
      ) : (
        <span
          className="pi pi-sun"
          onClick={() => changeMyTheme()}
          style={{ cursor: "pointer" }}
        />
      )}
      <Button
        label="Log Out"
        icon="pi pi-power-off"
        severity="danger"
        size="small"
        onClick={() => logout()}
      />
    </div>
  );
  const logout = () => {
    // localStorage.removeItem("soarxexactmail");
    Navigate("/");
  };
  return (
    <div>
      <div className="card userappbar">
        <Menubar model={items} start={start} end={end} />
      </div>
    </div>
  );
};
