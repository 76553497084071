// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pendingkyc {
  min-height: 100vh;
  padding: 80px 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/pendingkyc/pendingkyc.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;EACA,kBAAA;AAAJ","sourcesContent":["@import \"../../../style.scss\";\n.pendingkyc {\n    min-height: 100vh;\n    padding: 80px 20px;\n      // background: rgb(76, 113, 164);\n      // background: linear-gradient(\n      //   180deg,\n      //   rgba(76, 113, 164, 1) 0%,\n      //   rgba(196, 240, 233, 0) 100%\n      // );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
